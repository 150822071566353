<template>
  <h2 class="hidden">
    Discover history, one day at a time
  </h2>
</template>

<script setup>
import { Preferences } from '@capacitor/preferences';

definePageMeta({
  layout: 'splash'
})

onMounted(async () => {
  const { getMediasByDate } = await useMedias()
  const { $appFetch } = useNuxtApp()
  const today = new Date().toISOString().slice(0, 10) // date util ?

  await $appFetch('/api/ai/wakeup')
  await useLocalNotifications()

  const { value: redirect } = await Preferences.get({ key: 'redirect' })
  if (redirect) {
    await Preferences.remove({ key: 'redirect'})
    await navigateTo(redirect, { replace: true })
    return
  }

  const todayImage = getMediasByDate(today)
  if (todayImage?.length) await navigateTo('/calendrier', { replace: true })
  else await navigateTo(`/theme/${today}/camera`, { replace: true })
})
</script>